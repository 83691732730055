<template>
<v-app>
    <v-main>
        <v-container class="fill-height" fluid primary>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="elevation-4">
                        <v-toolbar color="primary" dark flat>
                            <v-toolbar-title>
                                <img src="@/assets/img/logo_sem_texto.svg" height="56" />
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-form>
                                <v-text-field label="Usuário" name="login" prepend-icon="mdi-account" type="text" v-model="username" :error-messages="errors['username']" @keyup.enter="login"></v-text-field>
                                <v-text-field id="password" label="Senha" name="password" prepend-icon="mdi-lock" type="password" v-model="password" :error-messages="errors['password']" @keyup.enter="login"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="login" :loading="loading">Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
import {
    User
} from "@/api/account";
export default {
    name: "Login",
    data: () => ({
        username: "",
        password: "",
        loading: false,
        errors: [],
    }),
    methods: {
        login() {
            this.loading = true;
            User.login(this.username, this.password)
                .then((response) => {
                    localStorage.setItem("token", response.data.token);
                    User.me().then((response) => {
                        this.$state.user = response.data;
                        if (response.data.is_eqpdti_uo) {
                            this.$state.role = "eqpdtiuo";
                        } else if (response.data.is_eqpdti) {
                            this.$state.role = "eqpdti";
                        } else if (response.data.is_comissao) {
                            this.$state.role = "comissao";
                        } else {
                            this.$state.role = "superuser";
                        }
                        this.$router.push({
                            name: "app:home",
                        });
                    });
                })
                .catch((error) => {
                    this.errors = error.response.data;
                    if (this.errors["non_field_errors"]) {
                        this.$emitter.emit("alert:error", this.errors["non_field_errors"]);
                    } else {
                        this.$emitter.emit("alert:error", this.errors);
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>
